//label
label {
  color: $label-color;
  font-size: $label-font-size-lg;
  font-family: $label-font-family;
}

//label + icon help
label i {
  margin-left: 0.25rem;
}

//caption
p[class^="input-caption"] {
  color: $input-caption-error-color;
  font-family: $input-caption-font-family;
  font-size: $input-caption-font-size-lg;
  margin-top: $input-caption-margin-top;
  &.error {
    color: $input-caption-error-color;
  }
}
.icon-input-custom {
  position: absolute;
  top: 68%;
  transform: translateY(-50%);
  right: 3%;
  color: $input-caption-error-color;
}
.icon-input-custom-phone {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  right: -10%;
  color: $input-caption-error-color;
}
.icon-input-custom-phone-error {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  right: 3%;
  color: $input-caption-error-color;
}

//input state
//ERROR
.errors input.ng-dirty {
  border-color: $input-caption-error-color;
}
.dirty,
.dirty:hover,
.dirty:focus,
.dirty:focus:active {
  border-color: var(--status-error-2, #d00000) !important;
  background: var(--Background-White, #fff) !important;
}
label .input-icon-label-help {
  margin-left: $icon-label-margin-right;
}
//DISABLED
textarea:disabled,
textarea.disabled,
input:disabled,
input.disabled {
  color: $gray-300;
  border-color: $input-disabled-border !important;
  background: $input-disabled-border !important;
  &::placeholder {
    opacity: 0.5;
  }
  &:hover,
  &:focus,
  &:focus:active {
    background: $input-disabled-border !important;
    border: 1px solid $input-disabled-border !important;
  }
}

input:not(:disabled) {
  color: #00008c !important;
  border-radius: 8px !important;
  border: 1px solid var(--Values-Grey-10, #ededf2);
  background: var(--Background-White, #fff);
  &:hover {
    color: #00008c !important;
    border: 1px solid var(--Action-Cobalt-10, #e5e5fd);
    background: var(--Action-Cobalt-05, #f2f2ff);
  }
  &:focus {
    color: #00008c !important;
    border: 1px solid var(--Action-Selected, #00c);
    background: var(--Background-White, #fff);
  }
  &:focus:active {
    border: 1px solid var(--Action-Click, #001eae);
    background: var(--Action-Cobalt-10, #e5e5fd);
  }
}

#app-input-test-mail:not(:disabled) {
  color: #0000cc !important;
}

//icon input gray if init state
input.ng-pristine + i {
  color: $lynch;
}

//input button action
.input-wrapper {
  position: relative;
  .action-input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    color: $primary;
  }
}
//input icon
.icon-input {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  color: $active !important;
}
.icon-input-custom {
  position: absolute;
  top: 68%;
  transform: translateY(-50%);
  right: 1rem;
  color: $input-caption-error-color;
}
//input password
.wrapper-rules {
  min-width: 50%;
  .rule {
    align-items: center;
  }

  p {
    font-size: 0.75rem;
  }
}

//input tel
.iti {
  display: inherit;
  width: 100%;
}
//responsive
@include media-breakpoint-down(sm) {
  label {
    font-size: $label-font-size-sm;
  }
  p[class^="input-caption"] {
    font-size: $input-caption-font-size-sm;
  }
}
@include media-breakpoint-down(md) {
  label {
    font-size: $label-font-size-md;
  }
  p[class^="input-caption"] {
    font-size: $input-caption-font-size-md;
  }
  //input password
  .wrapper-rules {
    min-width: 100%;
  }
}

textarea,
input {
  border-radius: 8px !important;
  border: 1px solid var(--Values-Grey-10, #ededf2);
  background: var(--Background-White, #fff) !important;
}

textarea:hover,
input:hover {
  border: 1px solid var(--Action-Cobalt-10, #e5e5fd) !important;
}

textarea:focus:active,
input:focus:active {
  border: 1px solid var(--Action-Click, #001eae) !important;
  background: var(--Action-Cobalt-10, #e5e5fd) !important;
}

textarea:focus,
input:focus {
  /* Matches when input is not empty */
  border: 1px solid var(--Action-Selected, #00c) !important;
  background: var(--Background-White, #fff) !important;
}
textarea:disabled,
input:disabled {
  border: 1px solid var(--Action-Cobalt-10, #e5e5fd) !important;
  background: var(--Action-Cobalt-05, #f2f2ff) !important;
}

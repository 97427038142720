// Modal background
.modal-backdrop {
  // Fade for backdrop
  &.fade {
    @include animation("fadeIn 0.5s linear");
  }

  &.show {
    @include animation("fadeIn 0.5s linear");
  }
}

// Modal
.modal {
  .close {
    top: 0;
    margin: 0;
    border-radius: $border-radius;
    color: $modal-header-color;
    opacity: 1;
    position: relative;
    background: transparent;
    right: 0;
    padding: 0;
    font-size: 1.5rem;
  }

  .modal-body {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: $modal-inner-padding-sm;
  }

  .modal-dialog {
    display: flex;
    -ms-flex-align: center;
    max-width: initial;
    min-height: 100vh;
    margin: 0;
    width: $modal-sm;
    left: initial;
    padding-right: 0;
    align-items: flex-end;
  }

  // Fade for modal content
  &.fade .modal-content {
    @include animation("translate 0.5s linear");
  }

  &.show .modal-content {
    @include animation("translate 0.5s linear");
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  align-items: center;
  background: $modal-header-bg;
  color: $modal-header-color;
  position: relative;
  flex-shrink: 0;
  padding: $modal-header-padding-y-sm $modal-header-padding-x-sm;
}

.modal-content {
  margin: $modal-dialog-margin-t-sm 0 0 0;
  max-height: fit-content;
  border-radius: $border-radius $border-radius 0 0;
}

.modal-content > .modal-content-wrapper {
  display: flex;
  max-height: calc(100vh - #{$modal-dialog-margin-t-sm});
  flex-direction: column;
}

// Footer (for actions)
.modal-footer {
  flex-shrink: 0;
  padding: $modal-footer-padding-y-sm ($modal-footer-padding-x-sm - calc($modal-footer-margin-between / 2));

  .btn {
    width: 100%;
  }
}

// Modal warning
.modal.warning {
  // Modal header
  .modal-header {
    background: $danger-500;

    & > *:not(.close) {
      color: $danger;
    }
  }
}

.modal.hide-workflow,
.modal.release-note {
  z-index: 10001;
  padding-right: 0 !important;

  .modal-dialog {
    width: 80% !important;
  }

  .modal-dialog-scrollable .modal-content {
    max-height: 90vh !important;
  }

  h3 {
    position: relative;
    font-size: 1rem;
    padding-right: 10rem;

    .not-available {
      color: $white;
      background-color: $bleu-tool;
      padding: 0.5rem 0.5rem 0.5rem 0.75rem;
      border-radius: 0.25rem;
      display: inline-block;
      font-size: 0.75rem;
      margin: 0 0 0 0.5rem;
      position: relative;
      top: 0;
      font-family: $Barlow;

      i {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
      }

      .icon-info-fill::before {
        color: inherit;
        width: 14px;
        height: 14px;
      }
    }

    .release-version {
      color: $gray-50;
      font-size: 0.75rem;
      position: absolute;
      left: auto;
      right: 2rem;
      top: 0.5rem;
      font-family: $Barlow;
    }
  }

  .Textbox {
    padding: 12px 16px !important;
    border-radius: 8px !important;
    background-color: #eff2ff !important;
    border: none;
    font-family: 'Barlow';

    p {
      color: #335bfa !important;
    }

    ul li {
      color: #335bfa !important;
    }

    i {
      width: 16px;
      color: #335bfa !important;
    }
  }

  .content-release {
    li,
    p {
      margin-bottom: 1.2rem;
      font-size: 0.88rem;
      color: $ultramarine;
    }

    figure {
      margin-bottom: 1.2rem;

      figcaption {
        font-size: 0.88rem;
        text-align: center;
        color: $gray-50;
        font-family: $Barlow;
      }
    }

    img {
      border: 1px solid $gray-30;
    }

    li {
      margin-bottom: 0.8rem;
      list-style: disc;
      margin-left: 1rem;
    }
  }

  .close {
    position: relative;
    background: $modal-content-bg;
    right: 0;
    padding: 0.5rem;
    font-size: 1.25rem;
    border: 3px solid $white !important;

    &:focus {
      border: 3px solid rgba($focus, 0.25) !important;
    }
  }
}

.modal.hide-workflow {
  z-index: 1056;

  .modal-dialog {
    width: $modal-sm !important;
  }
}

// Responsive
// Tablet
@include media-breakpoint-up(md) {
  .modal-header {
    padding: $modal-header-padding-y $modal-header-padding-x;
  }

  .modal.hide-workflow .modal-dialog {
    width: calc(100% - (#{$modal-dialog-margin-md} * 2)) !important;
  }

  .modal .modal-dialog {
    align-items: center;
    width: calc(100% - (#{$modal-dialog-margin-md} * 2));
    margin: 0 auto;
  }
  .modal-content {
    border-radius: $border-radius;
    margin: calc(#{$modal-dialog-margin-md} / 2) 0;
    max-height: fit-content;
  }
  .modal-content > .modal-content-wrapper {
    max-height: calc(100vh - #{$modal-dialog-margin-md});
  }
  .modal-body {
    padding: $modal-inner-padding;
  }
  .modal-footer {
    padding: $modal-footer-padding-y ($modal-footer-padding-x - calc($modal-footer-margin-between / 2));

    .btn {
      width: initial;
    }
  }
}

@include media-breakpoint-up(lg) {
  .modal .modal-dialog {
    width: $modal-lg;
    max-width: initial;
    align-items: center;
    justify-content: center;
    padding-right: 3.5rem;
  }
  .modal.hide-workflow .modal-dialog {
    width: 600px !important;
    padding-right: 0 !important;
  }

  .modal-content {
    margin: $modal-dialog-margin 0;
    max-height: fit-content;
  }
  .modal-content > .modal-content-wrapper {
    max-height: calc(100vh - (#{$modal-dialog-margin}) * 2);
  }
  .modal .close {
    position: absolute;
    background: $modal-content-bg;
    right: -3.5rem;
    padding: 0.75rem;
    font-size: 1.25rem;
  }
  .modal-edit-recipients.modal-dialog {
    width: 92% !important;
    padding-right: 0px !important;
    margin: 0 auto;
    height: 100% !important;
  }  .modal-send_mailTest.modal-dialog {
    width: 600px !important;
    padding-right: 0px !important;
  }
  .modal-edit-recipients > .modal-content {
    width: 100% !important;
  }
}

@include media-breakpoint-down(lg) {
  .modal.hide-workflow h3,
  .modal.release-note h3 {
    .release-version {
      position: revert;
      display: block;
      text-align: left;
      margin-top: 0.3rem;
    }
  }
  .modal-edit-recipients.modal-dialog {
    width: 90% !important;
  }
  .modal-send_mailTest.modal-dialog {
    width: 319px !important;
  }
  .modal-edit-recipients > .modal-content {
    width: 100% !important;
  }
}

@include keyframes(fadeIn) {
  0% {
    #{$browser}opacity: $modal-fade-backdrop-opacity;
  }
  100% {
    #{$browser}opacity: $modal-show-backdrop-opacity;
  }
}

@include keyframes(translate) {
  0% {
    #{$browser}transform: translateY(-54px);
  }
  100% {
    #{$browser}transform: translateY(0);
  }
}

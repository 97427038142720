/*!
 * Docaposte theme
 * Copyright 2020 Docaposte
 * Based on Bootstrap 4+
 */

// Colors Background & text & button
/* standar colors */
$black: #16161c;
$dark: #292933;
$white: #ffffff;
$light: #f8f8fb;

/* variables brand colors */
$primary: #0000ff;
/* variables brand secondary colors */
$ultramarine: #00008c;
/* variables status colors */
$info: #3970e6;
$info2: #335bfa;
$digital: #f2f2ff;
$mandy: #d7376c;
$danger: #d00000;
$warning: #f18f01;
$success: #589422;
$focus: #6884ff;
$hover: #0101e4;
$active: #0000cc;
$selected: #e5eaff;
$danger-500: #fef4f5;

/**
Workflow status variables
 */
$draft: #a85c10;
$draft-workflow-bg: #faf2e7;
$success-workflow-bg: #f4faf6;
$info2-workflow-bg: #eff2ff;
$danger-workflow-bg: $danger-500;
$timeout-workflow-bg: #f6f6f8;

/* variables gray's shade */
$gray-10: #ececef;
$gray-20: #dadbe0;
$gray-30: #c6c8d0;
$gray-40: #b4b6c1;
$gray-50: #666d92;
$gray-60: #8f92a1;
$gray-70: #7c7f91;
$gray-80: #696d82;
$gray-90: #565b72;
$gray-100: $gray-10;
$gray-200: $gray-20;
$gray-300: $gray-30;
$gray-400: $gray-40;
$gray-500: #a1a4b1;
$gray-600: $gray-60;
$gray-700: $gray-70;
$gray-800: $gray-80;
$gray-900: $gray-90;
$gray-1000: #444963;
$gray-title: #c4c4c4;
$gray-border-disabled: #dadce5;

/* variables only for text color */
$lynch: $gray-50;

/* variables only for background color */
$blue-10: #e5e5fd;
$blue-20: #ccccfb;
$blue-30: #b3b4f9;
$blue-40: #999af8;
$blue-50: #8182f7;
$blue-60: #6768f6;
$blue-70: #4d4ff5;
$blue-80: #3538f5;
$blue-90: #1d23f5;
$blue-100: #3351e0;

/* Docaposte theme color */
$theme-colors: (
  "primary": $primary,
  "ghost": $primary,
  "mandy": $mandy,
  "ultramarine": $ultramarine,
  "digital": $digital,
  "info": $info,
  "danger": $danger,
  "warning": $warning,
  "success": $success,
  "light": $light,
  "black": $black,
  "dark": $dark,
  "white": $white,
  "focus": $focus,
  "hover": $hover,
  "selected": $selected,
  "danger-500": $danger-500,
  "gray-300": $gray-300,
);
/* Docaposte only for text color */
$color-text: ();
$color-text: map-merge(
  (
    "lynch": $lynch,
  ),
  $color-text
);
/* Docaposte only for text color */
$grays: (
  "gray-10": $gray-10,
  "gray-20": $gray-20,
  "gray-30": $gray-30,
  "gray-40": $gray-40,
  "gray-50": $gray-50,
  "gray-60": $gray-60,
  "gray-70": $gray-70,
  "gray-80": $gray-80,
  "gray-90": $gray-90,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "gray-1000": $gray-1000,
  "gray-title": $gray-title,
);

/* Docaposte only for background color */
$color-background: ();
$color-background: map-merge(
  (
    "blue-10": $blue-10,
    "blue-20": $blue-20,
    "blue-30": $blue-30,
    "blue-40": $blue-40,
    "blue-50": $blue-50,
    "blue-60": $blue-60,
    "blue-70": $blue-70,
    "blue-80": $blue-80,
    "blue-90": $blue-90,
  ),
  $color-background
);

//body
$body-bg: $light;

// Typography
/* font-size-body */
$font-size-base: 1rem;
$font-size-context: 16;

/* font-family */
$font-family-sans-serif: $Barlow;
$font-family-base: $font-family-sans-serif;

/* font-weight */
$font-weight-normal: 400;
$font-weight-bold: $font-weight-normal;
$font-italic: normal;

/* font-size-title */
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.375;
$h4-font-size: $font-size-base * 1;
$h5-font-size: $font-size-base * 0.875;
$h6-font-size: $font-size-base * 0.875;

/* title properties */
$headings-lg-font-weight: 500;
$headings-sm-font-weight: 600;
$headings-lg-font-family: $MontserratSemiBold;
$headings-sm-font-family: $MontserratBold;
$headings-color: $ultramarine;
$headings-margin-bottom: 1.5rem;

/* font-size-subtitle */
$subtitle-lg-font-size: $font-size-base * 1.125;
$subtitle-sm-font-size: $font-size-base * 0.875;

/* subtitles properties*/
$subtitle-color: $ultramarine;
$subtitle-font-family: $BarlowMedium;
$subtitle-margin-bottom: 0;

/* font-size-paragraph */
$p-large: $font-size-base * 0.875;
$p-small: $font-size-base * 0.875;

/* font-size */
$font-size-8: 8;
$font-size-10: 10;
$font-size-12: 12;
$font-size-13: 13;
$font-size-14: 14;
$font-size-15: 15;
$font-size-16: 16;
$font-size-18: 18;
$font-size-22: 22;
$font-size-32: 32;
$font-size-40: 40;

/* Docaposte font-size map */
$font-sizes: ();
$font-sizes: map-merge(
  (
    "xxxxs": $font-size-8,
    "xxxs": $font-size-10,
    "xxs": $font-size-12,
    "xs": $font-size-13,
    "sm": $font-size-14,
    "md": $font-size-16,
    "lg": $font-size-18,
    "xl": $font-size-22,
    "xxl": $font-size-32,
    "xxxl": $font-size-40,
  ),
  $font-sizes
);

/* font weight italic */
$font-family-weight-bold: $BarlowBold;
$font-family-weight-bold-italic: $BarlowBoldItalic;
$font-family-weight-normal: $Barlow;
$font-family-weight-normal-italic: $BarlowItalic;
$font-family-weight-light: $BarlowLight;
$font-family-weight-light-italic: $BarlowLightItalic;

/* line clamp truncated text */
$line-2: 2;
$line-3: 3;
$line-4: 4;
$line-5: 5;

$lines-clamp: ();
$lines-clamp: map-merge(
  (
    "2": $line-2,
    "3": $line-3,
    "4": $line-4,
    "5": $line-5,
  ),
  $lines-clamp
);

//spacer
$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 2.75,
    ),
    6: (
      $spacer * 3,
    ),
  ),
  $spacers
);

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 0;

// Grid columns
$grid-gutter-width: 24px;

//Grid container gutter
$container-gutter: 40px;
$container-gutters: ();
$container-gutters: map-merge(
  (
    sm: $container-gutter * 0.5,
    md: $container-gutter,
    lg: $container-gutter * 2,
    xl: $container-gutter * 2,
  ),
  $container-gutters
);

//max width container
$container-max-widths: (
  lg: 1440px,
);

//border
$border-width: 1px;
$border-color: $gray-300;

$border-radius: 0.5rem;
$border-radius-lg: 0.625rem;
$border-radius-sm: 0.2rem;

//shadow
$box-shadow-sm:
  0px 4px 8px rgba(0, 0, 46, 0.04),
  0px 0px 2px rgba(0, 0, 45, 0.06),
  0px 0px 1px rgba(0, 0, 45, 0.04);
$box-shadow:
  0px 0px 0px rgba(0, 0, 46, 0.06),
  0px 1px 4px rgba(0, 0, 45, 0.1),
  0px 1px 10px rgba(0, 0, 45, 0.06);
$box-shadow-lg:
  0 0.063rem 0.625rem rgba(0, 0, 45, 0.07),
  0px 1px 4px rgba(0, 0, 45, 0.2),
  0px 0px 0px rgba(0, 0, 46, 0.07);

$box-shadow-docap: 0 4px 8px rgba(102, 109, 146, 0.08);

// Cards
$card-spacer-y: 2.75rem;
$card-spacer-x: 2.125rem;
$card-border-width: $border-width;
$card-border-radius: $border-radius;
$card-border-color: rgba($black, 0.125);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-bg: $white;
$card-cap-bg: $card-bg;
$card-box-shadow: $box-shadow;

// Toast
$toast-max-width: 27.813rem;
$toast-text-color: $gray-900;
$toast-arrow-color: $ultramarine;
$toast-inside-spacer-x: 1.813rem;
$toast-inside-spacer-y: 1.438rem;
$toast-border-radius: 0;
$toast-header-border-radius: $border-radius;

// Progress bars
$progress-height: 0.75rem;
$progress-bg: $gray-100;
$progress-border-radius: 0.375rem;

// Form
$label-margin-bottom: 0.4375rem;
$label-color: $ultramarine;
$label-font-size-sm: 0.75rem;
$label-font-size-md: 0.8125rem;
$label-font-size-lg: 0.875rem;
$label-font-family: $BarlowMedium;

$input-padding-y: 0.75rem;
$input-padding-x: 1rem;
$input-color: $ultramarine;
$input-placeholder-color: $lynch;
$input-line-height: 1.0625;

// Focus
$input-focus-border-color: $focus;
$input-font-size: 0.875rem;
$input-btn-focus-width: 0.2rem;
$input-btn-focus-color: rgba($focus, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

// Form error
$input-caption-error-color: $danger;
$input-caption-font-family: $font-family-weight-light-italic;
$input-caption-font-size-sm: 0.625rem;
$input-caption-font-size-md: 0.6875rem;
$input-caption-font-size-lg: 0.75rem;
$input-caption-margin-top: 0.5rem;

//svg label help
$icon-label-margin-right: 0.375rem;

//input disabled
$input-disabled-bg: $gray-100;
$input-disabled-border: $gray-100;

//button
$btn-border-radius: 1.875rem;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$btn-font-family: $MontserratMedium;

$btn-padding-y: 0.75rem;
$btn-padding-x: 1.75rem;
$btn-font-size: 0.875rem;
$btn-line-height: 1.25rem;

$btn-padding-y-lg: 0.875rem;
$btn-padding-x-lg: 2rem;
$btn-font-size-lg: 1rem;
$btn-line-height-lg: 1.25rem;

$btn-padding-y-sm: 0.625rem;
$btn-padding-x-sm: 1.5rem;
$btn-font-size-sm: 0.75rem;
$btn-line-height-sm: 1rem;

$btn-disabled-opacity: 1;

$btn-disabled-bg: $gray-100;
$btn-disabled-border: $gray-100;
$btn-disabled-color: $gray-300;

//checkboxes
$checkbox-unchecked-bg: $lynch;
$checkbox-checked-bg: $info;
$checkbox-indicator-bg: $white;

$checkbox-switch-border-radius: 1rem;

$checkbox-switch-width: 2.25rem;
$checkbox-switch-width-sm: 1.75rem;
$checkbox-switch-width-lg: 2.75rem;

$checkbox-border-size: 0.0625rem;
$checkbox-border-style: solid;

$checkbox-delay: 0.3s;
$checkbox-checked-animation: cubic-bezier(0.2, 0.8, 0.32, 1.2);
$checkbox-unchecked-animation: ease;

$checkbox-size: 1rem;
$checkbox-size-sm: 0.75rem;
$checkbox-size-lg: 1.125rem;

$checkbox-switch-padding: $checkbox-border-size;
$checkbox-checkbox-padding: 0.25rem;

$checkbox-radio-border-radius: 50%;

// Breadcrumbs
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $lynch;
$breadcrumb-active-color: $primary;
$breadcrumb-divider: quote("/");

$breadcrumb-border-radius: 0; // animations
$transition-slide-in-duration: 0.4s ease-in-out !default;
$transition-fade-duration: 0.15s linear !default;
$transition-fade: opacity $transition-fade-duration !default;

// Modals

// Padding applied to the modal body

$modal-content-border-color: $white !default;
$modal-content-border-radius: 0 !default;
$modal-content-border-width: 0 !default;
$modal-header-border-width: 1px !default;
$modal-footer-border-width: 0 !default;

$modal-footer-border-width: 0 !default;
$modal-aside-width: 320px !default;
$modal-aside-lg-width: 800px !default;

$modal-content-box-shadow-xs: 0 0 0 0 !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.1) !default;

// Tooltips
$tooltip-bg: $info;
$tooltip-color: $white;
$tooltip-padding-x: 0.875rem;
$tooltip-padding-y: 0.875rem;
$tooltip-border-radius: 0.5rem;
$tooltip-opacity: 1;

// Stepper
$stepper-item-active-bg: $primary;
$stepper-item-inactive-bg: $gray-200;
$stepper-item-selected-bg: $white;
$stepper-item-active-bd: $primary;
$stepper-item-inactive-bd: $gray-200;
$stepper-item-active-color: $ultramarine;
$stepper-item-inactive-color: $gray-200;
$stepper-item-selected-color: $primary;

//Datepicker
$datepicker-month-name-color: $ultramarine;
$datepicker-month-name-font-family: $MontserratMedium;
$datepicker-month-name-font-size: 0.875rem;

$datepicker-weekdays-name-color: $ultramarine;
$datepicker-weekdays-name-font-family: $BarlowMedium;
$datepicker-weekdays-name-font-size: 0.875rem;

$datepicker-indicator-hover-bg: $info;
$datepicker-indicator-hover-color: $white;

$datepicker-indicator-selected-bg: $ultramarine;
$datepicker-indicator-selected-color: $white;
$datepicker-indicator-bg: $info;
$datepicker-indicator-color: $white;
$datepicker-indicator-selected-border-radius: 1.5rem;
$datepicker-indicator-border-radius: 0;

$datepicker-indicator-width: 3rem;
$datepicker-indicator-height: $datepicker-indicator-width;

// accordeon

$accordeon-header-bg: transparent;
$accordeon-header-font-size: 1.5rem;
$accordeon-header-color: $ultramarine;

// Navbar
$navbar-padding-y: 1.4375rem;
$navbar-padding-x: 2rem;
$navbar-bg: $white;
$navbar-box-shadow: $box-shadow-lg;
$navbar-height: 4rem;

$navbar-link-color: $ultramarine;
$navbar-link-font-size: 0.875rem;
$navbar-link-font-family: $MontserratMedium;
$navbar-link-hover-color: $hover;
$navbar-link-active-color: $active;

//modal
$modal-dialog-margin: 2rem;
$modal-dialog-margin-md: 3rem;
$modal-dialog-margin-y-sm-up: 0;
$modal-dialog-margin-t-sm: 5rem;

$modal-header-padding-y: 1.5rem;
$modal-header-padding-x: 2rem;
$modal-header-padding-y-sm: 1rem;
$modal-header-padding-x-sm: 1.5rem;
$modal-header-bg: $digital;
$modal-header-color: $ultramarine;

$modal-content-border-width: 0;
$modal-content-border-radius: $border-radius;

$modal-backdrop-bg: $lynch;
$modal-backdrop-opacity: 0.3;

$modal-fade-backdrop-opacity: 0;
$modal-show-backdrop-opacity: $modal-backdrop-opacity;

$modal-inner-padding: $modal-header-padding-x;
$modal-inner-padding-sm: $modal-header-padding-x-sm;

$modal-footer-padding-y: $modal-header-padding-y;
$modal-footer-padding-x: $modal-header-padding-x;
$modal-footer-padding-y-sm: $modal-header-padding-y-sm;
$modal-footer-padding-x-sm: $modal-header-padding-x-sm;
$modal-footer-border-color: $gray-200;
$modal-footer-border-width: 0.0625rem;

$modal-lg: 800px;
$modal-sm: 100%;

$modal-fade-transform: translateY(-50px);
$modal-show-transform: none;

$modal-fade-transform-sm: translateY(-200%);
$modal-show-transform-sm: translateY(-100%);

$modal-transition: transform 0.5s ease-out;
$modal-scale-transform: scale(1.02) !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-link-active-bg: $blue-10;
$dropdown-link-hover-bg: $digital;

// Badges
$badge-font-size: 0.75rem;
$badge-font-weight: normal;
$badge-padding-y: 0.25rem;
$badge-padding-x: 0.625rem;
$badge-border-radius: 4px;

// Container
$padding-container: 72px;

// Table
$datatable-row-padding-y: 0;
$datatable-row-padding-x: 1.5rem;
$datatable-bg: $white;
$datatable-row-detail-border-radius: 0.5rem;
$datatable-footer-bg: $white;
$datatable-footer-padding-y: 0.3125rem;
$datatable-footer-padding-x: $datatable-footer-padding-y;
$datatable-footer-border-radius: $btn-border-radius;
$datatable-footer-box-shadow: $box-shadow;

//sperator
$separator-width: 0.0625rem;
$separator-height-sm: 1.125rem;
$separator-height-md: 2rem;
$separator-height-lg: 2.625rem;
$separator-margin-x-sm: 0.75rem;
$separator-margin-x-md: 1rem;
$separator-margin-x-lg: 1.125rem;

//table
$table-color: $ultramarine;
$table-border-color: $gray-200;

// Tabs
$nav-tabs-border-width: 1px;
$nav-tabs-border-color: $gray-200;

$nav-link-padding-x: 0;
$nav-tabs-item-margin-x: 1rem;

$nav-tabs-link-color: $gray-50;
$nav-tabs-link-font-family: $MontserratMedium;
$nav-tabs-link-font-size: 1rem;

$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-hover-color: $blue-100;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: $primary;
$nav-tabs-link-border-width: 3px;

$nav-pills-border-radius: 0;
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: transparent;
$nav-pill-color: $ultramarine;
$nav-pill-bg: $card-bg;
$nav-pill-padding: 1.5rem;

//progress
$progress-border-radius: $border-radius;
$progress-bar-color: $primary;
$progress-font-size: 1.375rem;
$progress-height: 0.6875rem;
$progress-font-family: $MontserratMedium;

//badges
//counter
$counter-width: 16px;
$counter-font-size: 12px;
$counter-font-family: $MontserratMedium;
$counter-border-radius: 1.25rem;
$counter-padding-x: 0.313rem;
$counter-padding-y: 0;

$red-tool: #d82a2a;
$grill-tool: $gray-50;
$vert-tool: #289d15;
$bleu-tool: $info;
$vert-status: #4a801a;
$red-status: $danger;

$refused-bg: $red-tool;
$cancel-bg: $gray-50;
$success-bg: $vert-tool;
$waiting-bg: $info;
$waiting-txt: $info;
$success-txt: $vert-status;
$error-txt: $danger;
$cancel-txt: $gray-50;
